import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { fluid: "", tag: "section" } },
    [
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3 mb-5",
          attrs: { color: "#7733FF", icon: "mdi-clipboard-text", inline: "" },
          scopedSlots: _vm._u([
            {
              key: "after-heading",
              fn: function () {
                return [
                  _c("h1", { staticClass: "h5" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("video_carousel")))]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VBtn,
            {
              attrs: {
                color: "primary",
                rounded: "",
                absolute: "",
                fab: "",
                top: "",
                right: "",
                "data-test": "MaterialB2BVideos:AddDialog:AddFile",
              },
              on: { click: _vm.handleOpenAddDialog },
            },
            [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
            1
          ),
          _c(VSelect, {
            staticClass: "language-options-select",
            staticStyle: { width: "92px" },
            attrs: {
              "background-color": "#f5f5f5",
              filled: "",
              items: _vm.languageOptions,
              "item-text": "text",
              "item-value": "value",
              "data-test": "MaterialB2BVideos:Language:ChangeLanguage",
            },
            on: { change: _vm.handleSelectLanguage },
            model: {
              value: _vm.selectedLanguage,
              callback: function ($$v) {
                _vm.selectedLanguage = $$v
              },
              expression: "selectedLanguage",
            },
          }),
          _vm.tableVideosLoading
            ? _c(
                "div",
                { staticClass: "items-center" },
                [
                  _c(VProgressCircular, {
                    attrs: { indeterminate: "", size: "60", color: "primary" },
                  }),
                ],
                1
              )
            : _c(VSimpleTable, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("title")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("visible")))]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("thumbnail"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$tc("video", 1))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(" " + _vm._s(_vm.$t("edit")) + " "),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(" " + _vm._s(_vm.$t("remove")) + " "),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.videos, function (video, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(video.file))]),
                      _c(
                        "td",
                        [
                          _vm.visibleVideoLoading === video.id
                            ? _c(VProgressCircular, {
                                attrs: {
                                  indeterminate: "",
                                  size: "20",
                                  color: "primary",
                                },
                              })
                            : _c(VCheckbox, {
                                staticClass: "field",
                                attrs: {
                                  color: "primary",
                                  outlined: "",
                                  dense: "",
                                  loading: true,
                                },
                                on: {
                                  change: function (e) {
                                    return _vm.handleVideoIsVisible(e, video)
                                  },
                                },
                                model: {
                                  value: video.visible,
                                  callback: function ($$v) {
                                    _vm.$set(video, "visible", $$v)
                                  },
                                  expression: "video.visible",
                                },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "px-2 ml-1 btnDownloadFile",
                              attrs: {
                                icon: "",
                                download: video.thumbFileName,
                                id: video.id,
                                name: video.thumbFileName,
                                color: "primary",
                                loading: _vm.fileDownloading === video.id,
                                "min-width": "0",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDownloadThumb(
                                    video.id,
                                    video.thumbFileName
                                  )
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-download"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("td", { staticClass: "text-center" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button-plat-video",
                            on: {
                              click: function ($event) {
                                return _vm.handleOpenDialogPlayVideo(
                                  video.videoId,
                                  video.videoPlatform
                                )
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "circle-play"] },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "px-2 ml-1 btn-download",
                              attrs: {
                                color: "secondary",
                                "min-width": "0",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEditVideo(video)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-pencil"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "px-2 ml-1",
                              attrs: {
                                color: "red",
                                "min-width": "0",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleOpenDialogDeleteVideo(video)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-trash-can"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ]),
        ],
        1
      ),
      _vm.dialog
        ? _c(
            VDialog,
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c("AddOrEditClip", {
                attrs: {
                  videos: _vm.videos,
                  videoToEdit: _vm.videoToEdit,
                  editVideo: _vm.editVideo,
                },
                on: {
                  successVideoRequest: _vm.handleSuccessVideoRequest,
                  closeDialog: _vm.closeVideoDialog,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.deleteDialog
        ? _c(
            VDialog,
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.deleteDialog,
                callback: function ($$v) {
                  _vm.deleteDialog = $$v
                },
                expression: "deleteDialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: { obj: _vm.deleteObj, name: _vm.deleteObj.file },
                on: {
                  close: function ($event) {
                    _vm.deleteDialog = false
                  },
                  delete: _vm.deleteVideo,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.videoDialog
        ? _c(
            VDialog,
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.videoDialog,
                callback: function ($$v) {
                  _vm.videoDialog = $$v
                },
                expression: "videoDialog",
              },
            },
            [_c("VideoDialog", { attrs: { video: _vm.videoToOpen } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }